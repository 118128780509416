import acousticGuitarTuning from "./additional/acoustic-guitar-tuning";
import bassGuitarTuning from "./additional/bass-guitar-tuning";
import ukuleleTuning from "./additional/ukulele-tuning";
import electricGuitarTuning from "./additional/electric-guitar-tuning";
import home from "./additional/home";

const additionals = {
  "acoustic-guitar-tuning": acousticGuitarTuning,
  "electric-guitar-tuning": electricGuitarTuning,
  "bass-guitar-tuning": bassGuitarTuning,
  "ukulele-tuning": ukuleleTuning,
  home: home,
};

const strings = {
  /***** Alerts *****/

  "error--MicInit--NotAllowed": {
    en: "Automatic tuning needs permission to use your device's microphone. Please allow access to the microphone in the settings.",
    es: "La sintonización automática necesita permiso para usar el micrófono de su dispositivo. Permita el acceso al micrófono en la configuración.",
    fr: "Le réglage automatique nécessite une autorisation pour utiliser le microphone de votre appareil. Veuillez autoriser l'accès au microphone dans les paramètres.",
    zh: "自动调谐需要获得使用设备麦克风的许可。 请在设置中允许访问麦克风。",
    de: "Die automatische Sendersuche erfordert die Berechtigung, das Mikrofon Ihres Geräts zu verwenden. Bitte erlauben Sie den Zugriff auf das Mikrofon in den Einstellungen.",
    id: "Penyetelan otomatis membutuhkan izin untuk menggunakan mikrofon perangkat Anda. Harap izinkan akses ke mikrofon di pengaturan.",
    ko: "자동 튜닝을 위해 장치의 마이크 사용 권한이 필요합니다. 설정에서 마이크 액세스를 허용하십시오.",
    bg: "Автоматично настройване изисква разрешение за използване на микрофона на вашето устройство. Моля, разрешете достъп до микрофона в настройките.",
    jp: "自動調整には、デバイスのマイクの使用許可が必要です。 設定でマイクへのアクセスを許可してください。",
    pt: "A sintonização automática precisa de permissão para usar o microfone do seu dispositivo. Por favor, permita o acesso ao microfone nas configurações.",
    tr: "Otomatik ayar için cihazınızın mikrofonunu kullanma izni gereklidir. Lütfen ayarlardan mikrofona erişim izni verin.",
    vi: "Điều chỉnh tự động cần quyền sử dụng micro của thiết bị của bạn. Vui lòng cho phép truy cập vào micro trong cài đặt.",
    tw: "自動調音需要許可使用您的設備的麥克風。 請在設置中允許訪問麥克風。",
    pl: "Automatyczne strojenie wymaga zgody na użycie mikrofonu w urządzeniu. Proszę zezwolić na dostęp do mikrofonu w ustawieniach.",
    it: "La sintonizzazione automatica richiede l'autorizzazione per utilizzare il microfono del tuo dispositivo. Consenti l'accesso al microfono nelle impostazioni.",
    sv: "Automatisk stämning behöver tillåtelse att använda din enhets mikrofon. Vänligen tillåt åtkomst till mikrofonen i inställningarna.",
    fi: "Automaattinen viritys tarvitsee luvan käyttää laitteen mikrofonia. Salli mikrofonin käyttö asetuksissa.",
    hi: "स्वचालित ट्यूनिंग के लिए आपके डिवाइस के माइक्रोफोन का उपयोग करने की अनुमति चाहिए। कृपया सेटिंग्स में माइक्रोफोन तक पहुंच की अनुमति दें।",
  },
  "error--MicInit--Unknown": {
    en: "Please use Safari 13+ or desktop Chrome, Firefox or Safari for tuning with microphone.",
    es: "Utilice Safari 13+ o Chrome, Firefox o Safari en el escritorio para afinar con el micrófono.",
    fr: "Veuillez utiliser Safari 13+ ou desktop Chrome, Firefox ou Safari pour le accorder avec microphone.",
    zh: "请使用Safari 13+或桌面Chrome、Firefox或Safari进行麦克风调谐。",
    de: "Bitte verwenden Sie Safari 13+ oder Desktop Chrome, Firefox oder Safari zum Abstimmen mit dem Mikrofon.",
    id: "Gunakan Safari 13+ atau desktop Chrome, Firefox, atau Safari untuk penyetelan dengan mikrofon.",
    ko: "마이크를 사용한 튜닝을 위해 Safari 13+ 또는 데스크톱 Chrome, Firefox 또는 Safari를 사용하십시오.",
    bg: "Моля, използвайте Safari 13+ или настолен Chrome, Firefox или Safari за настройване с микрофон.",
    jp: "マイクを使用して調整するには、Safari 13+またはデスクトップChrome、Firefox、Safariを使用してください。",
    pt: "Use o Safari 13+ ou o Chrome, Firefox ou Safari na área de trabalho para sintonizar com o microfone.",
    tr: "Mikrofon ile ayar yapmak için Safari 13+ veya masaüstü Chrome, Firefox veya Safari kullanın.",
    vi: "Vui lòng sử dụng Safari 13+ hoặc Chrome, Firefox hoặc Safari trên máy tính để điều chỉnh bằng micro.",
    tw: "請使用Safari 13+或桌面Chrome、Firefox或Safari進行麥克風調音。",
    pl: "Proszę używać Safari 13+ lub Chrome, Firefox lub Safari na komputerze do strojenia z mikrofonem.",
    it: "Utilizzare Safari 13+ o Chrome, Firefox o Safari su desktop per sintonizzare con il microfono.",
    sv: "Vänligen använd Safari 13+ eller skrivbords Chrome, Firefox eller Safari för att stämma med mikrofonen.",
    fi: "Käytä Safari 13+ tai työpöytäversioita Chrome, Firefox tai Safari mikrofonin kanssa viritykseen.",
    hi: "कृपया माइक्रोफ़ोन के साथ ट्यूनिंग के लिए Safari 13+ या डेस्कटॉप Chrome, Firefox या Safari का उपयोग करें।",
  },

  unsupportedBrowser: {
    en: "Please use Safari 13+ or desktop Chrome, Firefox or Safari for tuning with microphone.",
    es: "Utilice Safari 13+ o Chrome, Firefox o Safari en el escritorio para afinar con el micrófono.",
    fr: "Veuillez utiliser Safari 13+ ou desktop Chrome, Firefox ou Safari pour le accorder avec microphone.",
    zh: "请使用Safari 13+或桌面Chrome、Firefox或Safari进行麦克风调谐。",
    de: "Bitte verwenden Sie Safari 13+ oder Desktop Chrome, Firefox oder Safari zum Abstimmen mit dem Mikrofon.",
    id: "Gunakan Safari 13+ atau desktop Chrome, Firefox, atau Safari untuk penyetelan dengan mikrofon.",
    ko: "마이크를 사용한 튜닝을 위해 Safari 13+ 또는 데스크톱 Chrome, Firefox 또는 Safari를 사용하십시오.",
    bg: "Моля, използвайте Safari 13+ или настолен Chrome, Firefox или Safari за настройване с микрофон.",
    jp: "マイクを使用して調整するには、Safari 13+またはデスクトップChrome、Firefox、Safariを使用してください。",
    pt: "Use o Safari 13+ ou o Chrome, Firefox ou Safari na área de trabalho para sintonizar com o microfone.",
    tr: "Mikrofon ile ayar yapmak için Safari 13+ veya masaüstü Chrome, Firefox veya Safari kullanın.",
    vi: "Vui lòng sử dụng Safari 13+ hoặc Chrome, Firefox hoặc Safari trên máy tính để điều chỉnh bằng micro.",
    tw: "請使用Safari 13+或桌面Chrome、Firefox或Safari進行麥克風調音。",
    pl: "Proszę używać Safari 13+ lub Chrome, Firefox lub Safari na komputerze do strojenia z mikrofonem.",
    it: "Utilizzare Safari 13+ o Chrome, Firefox o Safari su desktop per sintonizzare con il microfono.",
    sv: "Vänligen använd Safari 13+ eller skrivbords Chrome, Firefox eller Safari för att stämma med mikrofonen.",
    fi: "Käytä Safari 13+ tai työpöytäversioita Chrome, Firefox tai Safari mikrofonin kanssa viritykseen.",
    hi: "कृपया माइक्रोफ़ोन के साथ ट्यूनिंग के लिए Safari 13+ या डेस्कटॉप Chrome, Firefox या Safari का उपयोग करें।",
  },

  /***** SEO *****/

  title: {
    en: "Guitar Tuner Online with Microphone | Guitar-Tuner.org",
    es: "Afinador de Guitarra con Micrófono Online | Guitar-Tuner.org",
    fr: "Accordeur de Guitare avec le Micro en ligne | Guitar-Tuner.org",
    zh: "吉他调谐器 | 在线调谐 | Guitar-Tuner.org",
    de: "Gitarren Stimmgerät mit Mikrofon | Online Stimmen | Guitar-Tuner.org",
    id: "Tuner Gitar Online dengan Mikrofon | Guitar-Tuner.org",
    ko: "마이크로 온라인 기타 튜너 | Guitar-Tuner.org",
    bg: "Онлайн тунер за китара с микрофон | Guitar-Tuner.org",
    jp: "マイク付きオンラインギターチューナー | Guitar-Tuner.org",
    pt: "Afinador de Guitarra Online com Microfone | Guitar-Tuner.org",
    tr: "Mikrofon ile Çevrimiçi Gitar Akort Cihazı | Guitar-Tuner.org",
    vi: "Tuner Guitar Online với Micro |Guitar-Tuner.org",
    tw: "吉他調音器 | 在線調音 | Guitar-Tuner.org",
    pl: "Stroik do gitary online z mikrofonem | Guitar-Tuner.org",
    it: "Accordatore di chitarra online con microfono | Guitar-Tuner.org",
    sv: "Gitarrstämning Online med Mikrofon | Guitar-Tuner.org",
    fi: "Verkkokitaran viritin mikrofonilla | Guitar-Tuner.org",
    hi: "माइक्रोफ़ोन के साथ ऑनलाइन गिटार ट्यूनर | Guitar-Tuner.org",
  },

  description: {
    en: "Tune your ukulele, acoustic, electric or bass guitar with microphone or by ear. Free online tuner for any guitar or ukulele.",
    es: "Afina tu ukelele, guitarra acústica, eléctrica o baja con un micrófono o de oído. El afinador online gratis para cualquiera guitarra o ukelele.",
    fr: "Accordez votre ukulélé, votre guitare acoustique, électrique ou basse avec un microphone ou à l'oreille. Accordeur en ligne gratuit pour la guitare ou ukulélé.",
    zh: "用麦克风或耳朵调好你的尤克里里、木吉他、电吉他或贝斯。免费在线调谐器适用于任何吉他或尤克里里。",
    de: "Stimmen Sie Ihre Ukulele, akustik, elektrische oder Bassgitarre mit dem Mikrofon oder nach Gehör. Kostenloses Online-Stimmgerät für die Gitarre oder Ukulele.",
    id: "Stem ukulele, gitar akustik, elektrik atau bass Anda dengan mikrofon atau dengan telinga. Tuner online gratis untuk semua jenis gitar atau ukulele.",
    ko: "마이크나 귀를 사용하여 우쿨렐레, 어쿠스틱, 일렉트릭 또는 베이스 기타를 조율하세요. 모든 기타나 우쿨렐레에 대한 무료 온라인 튜너입니다.",
    bg: "Настройте вашето укулеле, акустична, електрическа или бас китара с микрофон или на ухо. Безплатен онлайн тунер за всяка китара или укулеле.",
    jp: "ウクレレ、アコースティックギター、エレクトリックギター、またはベースギターをマイクまたは耳で調整します。 すべてのギターやウクレレのための無料オンラインチューナー。",
    pt: "Ajuste seu ukulele, guitarra acústica, elétrica ou baixo com microfone ou de ouvido. Afinador online gratuito para qualquer guitarra ou ukulele.",
    tr: "Ukulele, akustik, elektrik veya bas gitarınızı mikrofon veya kulakla ayarlayın. Herhangi bir gitar veya ukulele için ücretsiz çevrimiçi akort cihazı.",
    vi: "Điều chỉnh ukulele, guitar acoustic, electric hoặc bass của bạn bằng micro hoặc bằng tai. Bộ chỉnh miễn phí trực tuyến cho bất kỳ loại guitar hoặc ukulele nào.",
    tw: "用麥克風或耳朵調好你的尤克里里、木吉他、电吉他或貝斯。免費在線調音器適用於任何吉他或尤克里里。",
    pl: "Strojenie ukulele, gitary akustycznej, elektrycznej lub basowej za pomocą mikrofonu lub na słuch. Darmowy stroik online do każdej gitary lub ukulele.",
    it: "Accorda il tuo ukulele, chitarra acustica, elettrica o basso con microfono o a orecchio. Accordatore online gratuito per qualsiasi chitarra o ukulele.",
    sv: "Stäm din ukulele, akustiska, elektriska eller basgitarr med mikrofon eller på gehör. Gratis online stämningsverktyg för vilken gitarr eller ukulele som helst.",
    fi: "Viritä ukulele, akustinen, sähköinen tai bassokitara mikrofonilla tai korvakuulolta. Ilmainen verkkoviritin kaikille kitaroille tai ukuleleille.",
    hi: "अपने उकलुले, ध्वनिक, इलेक्ट्रिक या बास गिटार को माइक्रोफ़ोन या कान से ट्यून करें। किसी भी गिटार या उकलुले के लिए मुफ्त ऑनलाइन ट्यूनर।",
  },

  "title-acoustic-guitar": {
    en: "Acoustic Guitar Tuner with Microphone Online | Guitar-Tuner.org",
    es: "Afinador de Guitarra Acústica con Micrófono Online | Guitar-Tuner.org",
    fr: "Accordeur De Guitare Acoustique avec le Micro | Guitar-Tuner.org",
    zh: "木吉他调谐器 | 在线木吉他调音 | Guitar-Tuner.org",
    de: "Stimmgerät Akustik-Gitarre mit Mikrofon Online | Guitar-Tuner.org",
    id: "Tuner Gitar Akustik dengan Mikrofon Online | Guitar-Tuner.org",
    ko: "마이크로 온라인 어쿠스틱 기타 튜너 | Guitar-Tuner.org",
    bg: "Тунер за акустична китара с микрофон онлайн | Guitar-Tuner.org",
    jp: "マイク付きオンラインアコースティックギターチューナー | Guitar-Tuner.org",
    pt: "Afinador de Guitarra Acústica Online com Microfone | Guitar-Tuner.org",
    tr: "Mikrofon ile Çevrimiçi Akustik Gitar Akort Cihazı | Guitar-Tuner.org",
    vi: "Tuner Guitar Acoustic Online với Micro | Guitar-Tuner.org",
    tw: "木吉他調音器 | 在線木吉他調音 | Guitar-Tuner.org",
    pl: "Stroik do gitary akustycznej online z mikrofonem | Guitar-Tuner.org",
    it: "Accordatore di chitarra acustica online con microfono | Guitar-Tuner.org",
    sv: "Akustisk Gitarrstämning Online med Mikrofon | Guitar-Tuner.org",
    fi: "Akustinen kitaran viritin mikrofonilla verkossa | Guitar-Tuner.org",
    hi: "माइक्रोफ़ोन के साथ ध्वनिक गिटार ट्यूनर ऑनलाइन | Guitar-Tuner.org",
  },

  "description-acoustic-guitar": {
    en: "Easily tune your acoustic guitar with microphone or by ear. This precise acoustic guitar tuner will make your guitar sound perfect.",
    es: "Afina fácilmente tu guitarra acústica con un micrófono o de oído. Este afinador para guitarra acústica hará tu guitarra sonar perfectamente.",
    fr: "Accordez votre guitare acoustique avec un microphone ou à l'oreille. Cet accordeur de guitare précis vous aidera d'obtenir un son parfait.",
    zh: "用麦克风或耳朵轻松地调好你的木吉他。这个木吉他调谐器将使你的吉他听起来完美。简单而精确的音响调谐器。",
    de: "Stimmen Sie Ihre Akustik-Gitarre mit einem Mikrofon oder nach Gehör. Dieses präzises Stimmgerät wird Ihre Gitarre perfekt klingen lassen.",
    id: "Stem gitar akustik Anda dengan mudah menggunakan mikrofon atau dengan telinga. Tuner gitar akustik ini akan membuat gitar Anda terdengar sempurna.",
    ko: "마이크나 귀를 사용하여 어쿠스틱 기타를 쉽게 조율하세요. 이 정밀한 어쿠스틱 기타 튜너가 기타 소리를 완벽하게 만들어줍니다.",
    bg: "Лесно настройте акустичната си китара с микрофон или на ухо. Този прецизен тунер ще направи китарата ви да звучи перфектно.",
    jp: "マイクまたは耳を使ってアコースティックギターを簡単に調整します。この正確なアコースティックギターチューナーがギターの音を完璧にします。",
    pt: "Ajuste facilmente sua guitarra acústica com microfone ou de ouvido. Este afinador de guitarra acústica preciso fará sua guitarra soar perfeitamente.",
    tr: "Akustik gitarınızı mikrofon veya kulakla kolayca ayarlayın. Bu hassas akustik gitar akort cihazı, gitarınızın mükemmel ses vermesini sağlar.",
    vi: "Dễ dàng điều chỉnh guitar acoustic của bạn với micro hoặc bằng tai. Bộ chỉnh guitar acoustic chính xác này sẽ làm cho guitar của bạn phát ra âm thanh hoàn hảo.",
    tw: "用麥克風或耳朵輕鬆地調好你的木吉他。這個木吉他調音器將使你的吉他聽起來完美。簡單而精確的音響調音器。",
    pl: "Łatwo nastroisz swoją gitarę akustyczną za pomocą mikrofonu lub na słuch. Ten precyzyjny stroik sprawi, że Twoja gitara będzie brzmiała idealnie.",
    it: "Accorda facilmente la tuachitarra acustica con microfono o a orecchio. Questo preciso accordatore per chitarra acustica renderà il suono della tua chitarra perfetto.",
    sv: "Stäm enkelt din akustiska gitarr med mikrofon eller på gehör. Denna exakta akustiska gitarrstämning kommer att göra din gitarr perfekt.",
    fi: "Viritä akustinen kitarasi helposti mikrofonilla tai korvakuulolta. Tämä tarkka akustisen kitaran viritin tekee kitarasi äänestä täydellisen.",
    hi: "अपने ध्वनिक गिटार को माइक्रोफ़ोन या कान से आसानी से ट्यून करें। यह सटीक ध्वनिक गिटार ट्यूनर आपके गिटार की ध्वनि को संपूर्ण बना देगा।",
  },

  "title-electric-guitar": {
    en: "Electric Guitar Tuner with Microphone Online | Guitar-Tuner.org",
    es: "Afinador de Guitarra Eléctrica con Micrófono Online | Guitar-Tuner.org",
    fr: "Accordeur De Guitare Électrique avec le Micro | Guitar-Tuner.org",
    zh: "电吉他调谐器 | 在线电吉他调音 | Guitar-Tuner.org",
    de: "Stimmgerät E-Gitarre mit Mikrofon Online | Guitar-Tuner.org",
    id: "Tuner Gitar Elektrik dengan Mikrofon Online | Guitar-Tuner.org",
    ko: "마이크로 온라인 일렉트릭 기타 튜너 | Guitar-Tuner.org",
    bg: "Тунер за електрическа китара с микрофон онлайн | Guitar-Tuner.org",
    jp: "マイク付きオンラインエレクトリックギターチューナー | Guitar-Tuner.org",
    pt: "Afinador de Guitarra Elétrica Online com Microfone | Guitar-Tuner.org",
    tr: "Mikrofon ile Çevrimiçi Elektrik Gitar Akort Cihazı | Guitar-Tuner.org",
    vi: "Tuner Guitar Điện Online với Micro | Guitar-Tuner.org",
    tw: "電吉他調音器 | 在線電吉他調音 | Guitar-Tuner.org",
    pl: "Stroik do gitary elektrycznej online z mikrofonem | Guitar-Tuner.org",
    it: "Accordatore di chitarra elettrica online con microfono | Guitar-Tuner.org",
    sv: "Elektrisk Gitarrstämning Online med Mikrofon | Guitar-Tuner.org",
    fi: "Sähkökitaran viritin mikrofonilla verkossa | Guitar-Tuner.org",
    hi: "माइक्रोफ़ोन के साथ इलेक्ट्रिक गिटार ट्यूनर ऑनलाइन | Guitar-Tuner.org",
  },

  "description-electric-guitar": {
    en: "Easily tune your electric guitar with microphone or by ear. This precise electric guitar tuner will make your guitar sound perfect.",
    es: "Afina tu guitarra eléctrica con un micrófono o de oído. Este afinador para guitarra eléctrica hará tu guitarra sonar perfectamente.",
    fr: "Accordez votre guitare électrique avec un microphone ou à l'oreille. Cet accordeur de guitare précis vous aidera d'obtenir un son parfait.",
    zh: "用麦克风或耳朵轻松地调好你的电吉他。激励人心的电吉他调谐器。电吉他调音从未如此容易！",
    de: "Stimmen Sie Ihre E-Gitarre mit einem Mikrofon oder nach Gehör. Dieses präzises Stimmgerät wird Ihre Gitarre perfekt klingen lassen.",
    id: "Stem gitar elektrik Anda dengan mudah menggunakan mikrofon atau dengan telinga. Tuner gitar listrik ini akan membuat gitar Anda terdengar sempurna.",
    ko: "마이크나 귀를 사용하여 일렉트릭 기타를 쉽게 조율하세요. 이 정밀한 일렉트릭 기타 튜너가 기타 소리를 완벽하게 만들어줍니다.",
    bg: "Лесно настройте вашата електрическа китара с микрофон или на ухо. Този прецизен тунер ще направи китарата ви да звучи перфектно.",
    jp: "マイクまたは耳を使ってエレクトリックギターを簡単に調整します。この正確なエレクトリックギターチューナーがギターの音を完璧にします。",
    pt: "Ajuste facilmente sua guitarra elétrica com microfone ou de ouvido. Este afinador de guitarra elétrica preciso fará sua guitarra soar perfeitamente.",
    tr: "Elektrik gitarınızı mikrofon veya kulakla kolayca ayarlayın. Bu hassas elektrik gitar akort cihazı, gitarınızın mükemmel ses vermesini sağlar.",
    vi: "Dễ dàng điều chỉnh guitar điện của bạn với micro hoặc bằng tai. Bộ chỉnh guitar điện chính xác này sẽ làm cho guitar của bạn phát ra âm thanh hoàn hảo.",
    tw: "用麥克風或耳朵輕鬆地調好你的電吉他。這個精確的電吉他調音器將使你的吉他聽起來完美。",
    pl: "Łatwo nastroisz swoją gitarę elektryczną za pomocą mikrofonu lub na słuch. Ten precyzyjny stroik sprawi, że Twoja gitara będzie brzmiała idealnie.",
    it: "Accorda facilmente la tua chitarra elettrica con microfono o a orecchio. Questo preciso accordatore per chitarra elettrica renderà il suono della tua chitarra perfetto.",
    sv: "Stäm enkelt din elektriska gitarr med mikrofon eller på gehör. Denna exakta elektriska gitarrstämning kommer att göra din gitarr perfekt.",
    fi: "Viritä sähkökitarasi helposti mikrofonilla tai korvakuulolta. Tämä tarkka sähkökitaran viritin tekee kitarasi äänestä täydellisen.",
    hi: "अपने इलेक्ट्रिक गिटार को माइक्रोफ़ोन या कान से आसानी से ट्यून करें। यह सटीक इलेक्ट्रिक गिटार ट्यूनर आपके गिटार की ध्वनि को संपूर्ण बना देगा।",
  },

  "title-bass-guitar": {
    en: "Bass Tuner with Microphone Online | Guitar Tuning | Guitar-Tuner.org",
    es: "Afinador de Bajo con Micrófono Online | Guitar-Tuner.org",
    fr: "Accordeur De Guitare Basse avec le Micro | Guitar-Tuner.org",
    zh: "贝斯调谐器 | 在线贝斯吉他调音 | Guitar-Tuner.org",
    de: "Bass Stimmgerät mit Mikrofon Online | Guitar-Tuner.org",
    id: "Tuner Bass dengan Mikrofon Online | Guitar-Tuner.org",
    ko: "마이크로 온라인 베이스 튜너 | Guitar-Tuner.org",
    bg: "Тунер за бас китара с микрофон онлайн | Guitar-Tuner.org",
    jp: "マイク付きオンラインベースチューナー | Guitar-Tuner.org",
    pt: "Afinador de Baixo Online com Microfone | Guitar-Tuner.org",
    tr: "Mikrofon ile Çevrimiçi Bas Gitar Akort Cihazı | Guitar-Tuner.org",
    vi: "Tuner Bass Guitar Online với Micro | Guitar-Tuner.org",
    tw: "貝斯調音器 | 在線貝斯吉他調音 | Guitar-Tuner.org",
    pl: "Stroik do basu online z mikrofonem | Guitar-Tuner.org",
    it: "Accordatore di basso online con microfono | Guitar-Tuner.org",
    sv: "Basgitarrstämning Online med Mikrofon | Guitar-Tuner.org",
    fi: "Bassokitaran viritin mikrofonilla verkossa | Guitar-Tuner.org",
    hi: "माइक्रोफ़ोन के साथ बास ट्यूनर ऑनलाइन | Guitar-Tuner.org",
  },

  "description-bass-guitar": {
    en: "Easily tune your bass guitar with microphone or by ear. With this online tuner you will make your bass sound great. Simple and precise bass tuner.",
    es: "Afina fácilmente tu guitarra baja con un micrófono o de oído. Este afinador online hará tu bass sonar bien. Simple y preciso afinador bajo.",
    fr: "Accordez votre guitare basse en ligne avec un microphone ou à l'oreille . Grâce à cet accordeur précis, vous obtiendrez un excellent son de basse.",
    zh: "用麦克风或耳朵轻松地调好你的贝斯吉他。这个在线调谐器将使你的贝斯听起来完美。简单而精确的贝斯调谐器。",
    de: "Stimmen Sie Ihre Bass-Gitarre Online mit Mikrofon oder nach Gehör. Dieses präzises Stimmgerät wird Ihren Bass großartig klingen lassen.",
    id: "Stem gitar bass Anda dengan mudah menggunakan mikrofon atau dengan telinga. Dengan tuner online ini, bass Anda akan terdengar hebat. Tuner bass yang sederhana dan presisi.",
    ko: "마이크나 귀를 사용하여 베이스기타를 쉽게 조율하세요. 이 온라인 튜너를 사용하면 베이스가 훌륭하게 들리게 됩니다. 간단하고 정밀한 베이스 튜너입니다.",
    bg: "Лесно настройте вашата бас китара с микрофон или на ухо. С този онлайн тунер басът ви ще звучи страхотно. Прост и прецизен тунер за бас.",
    jp: "マイクまたは耳を使ってベースギターを簡単に調整します。このオンラインチューナーを使用すると、ベースが素晴らしい音になります。シンプルで正確なベースチューナーです。",
    pt: "Ajuste facilmente seu baixo com microfone ou de ouvido. Com este afinador online, você fará o seu baixo soar ótimo. Afinador de baixo simples e preciso.",
    tr: "Bas gitarınızı mikrofon veya kulakla kolayca ayarlayın. Bu çevrimiçi akort cihazı ile bas gitarınız harika ses verecek. Basit ve hassas bas gitar akort cihazı.",
    vi: "Dễ dàng điều chỉnh guitar bass của bạn với micro hoặc bằng tai. Với bộ chỉnh trực tuyến này, bạn sẽ làm cho âm thanh bass tuyệt vời. Bộ chỉnh bass đơn giản và chính xác.",
    tw: "用麥克風或耳朵輕鬆地調好你的貝斯吉他。這個在線調音器將使你的貝斯聽起來完美。簡單而精確的貝斯調音器。",
    pl: "Łatwo nastroisz swoją gitarę basową za pomocą mikrofonu lub na słuch. Ten precyzyjny stroik sprawi, że Twój bas będzie brzmiał świetnie. Prosty i precyzyjny stroik do basu.",
    it: "Accorda facilmente il tuo basso con microfono o a orecchio. Con questo accordatore online, il tuo basso suonerà alla grande. Accordatore per basso semplice e preciso.",
    sv: "Stäm enkelt din basgitarr med mikrofon eller på gehör. Med denna exakta basgitarrstämning kommer din bas att låta perfekt.",
    fi: "Viritä bassokitarasi helposti mikrofonilla tai korvakuulolta. Tämä tarkka bassokitaran viritin saa bassosi kuulostamaan loistavalta. Yksinkertainen ja tarkka bassoviritin.",
    hi: "अपने बास गिटार को माइक्रोफ़ोन या कान से आसानी से ट्यून करें। इस ऑनलाइन ट्यूनर से आपका बास शानदार लगेगा। सरल और सटीक बास ट्यूनर।",
  },

  "title-ukulele": {
    en: "Ukulele Tuner with Microphone Online | Guitar-Tuner.org",
    es: "Afinador de Ukelele con Micrófono Online | Guitar-Tuner.org",
    fr: "Accordeur d'Ukulélé avec le Micro en ligne | Guitar-Tuner.org",
    zh: "尤克里里调谐器 | 在线尤克里里调音 | Guitar-Tuner.org",
    de: "Ukulele Stimmgerät mit Mikrofon Online | Guitar-Tuner.org",
    id: "Tuner Ukulele dengan Mikrofon Online | Guitar-Tuner.org",
    ko: "마이크로 온라인 우쿨렐레 튜너 | Guitar-Tuner.org",
    bg: "Онлайн тунер за укулеле с микрофон | Guitar-Tuner.org",
    jp: "マイク付きオンラインウクレレチューナー | Guitar-Tuner.org",
    pt: "Afinador de Ukulele Online com Microfone | Guitar-Tuner.org",
    tr: "Mikrofon ile Çevrimiçi Ukulele Akort Cihazı | Guitar-Tuner.org",
    vi: "Tuner Ukulele Online với Micro | Guitar-Tuner.org",
    tw: "尤克里里調音器 | 在線尤克里里調音 | Guitar-Tuner.org",
    pl: "Stroik do ukulele online z mikrofonem | Guitar-Tuner.org",
    it: "Accordatore di ukulele online con microfono | Guitar-Tuner.org",
    sv: "Ukulele Stämning Online med Mikrofon | Guitar-Tuner.org",
    fi: "Ukulelen viritin mikrofonilla verkossa | Guitar-Tuner.org",
    hi: "माइक्रोफ़ोन के साथ उकलुले ट्यूनर ऑनलाइन | Guitar-Tuner.org",
  },

  "description-ukulele": {
    en: "Tune your ukulele online with microphone or by ear. Simple and precise tuner makes everyday uke tuning easy.",
    es: "Afina tu ukelele con un micrófono o de oído. Afinar tu ukelele nunca ha sido tan facil. Simple y preciso afinador por guitar-tuner.org.",
    fr: "Accordez votre ukulélé avec un microphone ou à l'oreille. Un accordeur simple et précis fait l'accord d'ukulélé très facile.",
    zh: "用麦克风或耳朵轻松地调好你的尤克里里。 在线尤克里里调音从未如此容易！简单而精确的尤克里里调谐器 guitar-tuner.org.",
    de: "Stimmen Sie Ihre Ukulele mit dem Mikrofon oder nach Gehör. Dieses präzise Stimmgerät wird Ihre Ukulele großartig klingen lassen.",
    id: "Stem ukulele Anda secara online menggunakan mikrofon atau dengan telinga. Tuner sederhana dan presisi ini membuat penyetelan ukulele sehari-hari menjadi mudah.",
    ko: "마이크나 귀를 사용하여 우쿨렐레를 온라인으로 조율하세요. 간단하고 정밀한 튜너가 일상적인 우쿨렐레 튜닝을 쉽게 만듭니다.",
    bg: "Настройте вашето укулеле онлайн с микрофон или на ухо. Прост и прецизен тунер, който прави настройването на укулеле лесно всеки ден.",
    jp: "マイクまたは耳を使ってウクレレをオンラインで調整します。シンプルで正確なチューナーにより、毎日のウクレレ調整が簡単になります。",
    pt: "Ajuste seu ukulele online com microfone ou de ouvido. Afinador simples e preciso torna o ajuste diário do uke fácil.",
    tr: "Ukulelenizi çevrimiçi olarak mikrofon veya kulakla ayarlayın. Basit ve hassas akort cihazı günlük ukulele akort işlemlerini kolaylaştırır.",
    vi: "Điều chỉnh ukulele của bạn trực tuyến với micro hoặc bằng tai. Bộ chỉnh đơn giản và chính xác giúp việc chỉnh âm hàng ngày trở nên dễ dàng.",
    tw: "用麥克風或耳朵在線調好你的尤克里里。簡單而精確的調音器使每天的尤克里里調音變得容易。",
    pl: "Nastroj swoje ukulele online za pomocą mikrofonu lub na słuch. Prosty i precyzyjny stroik ułatwia codzienne strojenie ukulele.",
    it: "Accorda il tuo ukulele online con microfono o a orecchio. Un accordatore semplice e preciso rende facile l'accordatura quotidiana dell'ukulele.",
    sv: "Stäm din ukulele online med mikrofon eller på gehör. En enkel och exakt stämningsapparat gör daglig stämning av ukulele lätt.",
    fi: "Viritä ukuleleasi verkossa mikrofonin tai korvakuulon avulla. Yksinkertainen ja tarkka viritin tekee päivittäisestä ukulelen virittämisestä helppoa.",
    hi: "अपने उकलुले को ऑनलाइन माइक्रोफ़ोन या कान से ट्यून करें। सरल और सटीक ट्यूनर आपके रोज़ के उकलुले ट्यूनिंग को आसान बनाता है।",
  },

  /***** Instruments *****/

  acoustic: {
    en: "Acoustic",
    es: "Acústica",
    fr: "Acoustique",
    zh: "木",
    de: "Akustik",
    id: "Akustik",
    ko: "어쿠스틱",
    bg: "Акустична",
    jp: "アコースティック",
    pt: "Acústico",
    tr: "Akustik",
    vi: "Acoustic",
    tw: "木",
    pl: "Akustyczna",
    it: "Acustica",
    sv: "Akustisk",
    fi: "Akustinen",
    hi: "ध्वनिक",
  },
  acousticJSX: {
    en: (
      <span>
        Acoustic<span className="xl-only">&nbsp;Guitar</span>
      </span>
    ),
    es: (
      <span>
        <span className="xl-only">Guitarra&nbsp;</span>Acústica
      </span>
    ),
    fr: (
      <span>
        <span className="xl-only">Guitare&nbsp;</span>Acoustique
      </span>
    ),
    zh: <span>木吉他</span>,
    de: (
      <span>
        Akustik<span className="xl-only">&nbsp;Gitarre</span>
      </span>
    ),
    id: (
      <span>
        Akustik<span className="xl-only">&nbsp;Gitar</span>
      </span>
    ),
    ko: (
      <span>
        어쿠스틱<span className="xl-only">&nbsp;기타</span>
      </span>
    ),
    bg: (
      <span>
        Акустична<span className="xl-only">&nbsp;Китара</span>
      </span>
    ),
    jp: (
      <span>
        アコースティック<span className="xl-only">&nbsp;ギター</span>
      </span>
    ),
    pt: (
      <span>
        Acústico<span className="xl-only">&nbsp;Violão</span>
      </span>
    ),
    tr: (
      <span>
        Akustik<span className="xl-only">&nbsp;Gitar</span>
      </span>
    ),
    vi: (
      <span>
        Acoustic<span className="xl-only">&nbsp;Guitar</span>
      </span>
    ),
    tw: <span>木吉他</span>,
    pl: (
      <span>
        Akustyczna<span className="xl-only">&nbsp;Gitara</span>
      </span>
    ),
    it: (
      <span>
        Acustica<span className="xl-only">&nbsp;Chitarra</span>
      </span>
    ),
    sv: (
      <span>
        Akustisk<span className="xl-only">&nbsp;Gitarr</span>
      </span>
    ),
    fi: (
      <span>
        Akustinen<span className="xl-only">&nbsp;Kitara</span>
      </span>
    ),
    hi: <span>ध्वनिक गिटार</span>,
  },
  tune_acoustic: {
    en: "Tune Acoustic",
    es: "Afinar Acústica",
    fr: "Accorder Acoustic",
    zh: "调木吉他",
    de: "Akustik Stimmen",
    id: "Stem Akustik",
    ko: "어쿠스틱 조율",
    bg: "Настройване на Акустична",
    jp: "アコースティックの調整",
    pt: "Afinar Acústico",
    tr: "Akustik Ayarlama",
    vi: "Điều chỉnh Acoustic",
    tw: "調木吉他",
    pl: "Strojenie Akustycznej",
    it: "Accordare Acustica",
    sv: "Stäm Akustisk",
    fi: "Viritä Akustinen",
    hi: "ध्वनिक ट्यून करें",
  },
  bass: {
    en: "Bass",
    es: "Bajo",
    fr: "Basse",
    zh: "贝斯",
    de: "Bass",
    id: "Bass",
    ko: "베이스",
    bg: "Бас",
    jp: "ベース",
    pt: "Baixo",
    tr: "Bas",
    vi: "Bass",
    tw: "貝斯",
    pl: "Bas",
    it: "Basso",
    sv: "Bas",
    fi: "Basso",
    hi: "बास",
  },
  bassJSX: {
    en: (
      <span>
        Bass<span className="xl-only">&nbsp;Guitar</span>
      </span>
    ),
    es: <span>Bajo</span>,
    fr: (
      <span>
        <span className="xl-only">Guitare&nbsp;</span>Basse
      </span>
    ),
    zh: <span>贝斯吉他</span>,
    de: (
      <span>
        Bass<span className="xl-only">&nbsp;Gitarre</span>
      </span>
    ),
    id: (
      <span>
        Bass<span className="xl-only">&nbsp;Gitar</span>
      </span>
    ),
    ko: (
      <span>
        베이스<span className="xl-only">&nbsp;기타</span>
      </span>
    ),
    bg: (
      <span>
        Бас<span className="xl-only">&nbsp;Китара</span>
      </span>
    ),
    jp: (
      <span>
        ベース<span className="xl-only">&nbsp;ギター</span>
      </span>
    ),
    pt: (
      <span>
        Baixo<span className="xl-only">&nbsp;Guitarra</span>
      </span>
    ),
    tr: (
      <span>
        Bas<span className="xl-only">&nbsp;Gitar</span>
      </span>
    ),
    vi: (
      <span>
        Bass<span className="xl-only">&nbsp;Guitar</span>
      </span>
    ),
    tw: <span>貝斯吉他</span>,
    pl: (
      <span>
        Bas<span className="xl-only">&nbsp;Gitara</span>
      </span>
    ),
    it: (
      <span>
        Basso<span className="xl-only">&nbsp;Chitarra</span>
      </span>
    ),
    sv: (
      <span>
        Bas<span className="xl-only">&nbsp;Gitarr</span>
      </span>
    ),
    fi: (
      <span>
        Basso<span className="xl-only">&nbsp;Kitara</span>
      </span>
    ),
    hi: (
      <span>
        बास<span className="xl-only">&nbsp;गिटार</span>
      </span>
    ),
  },
  tune_bass: {
    en: "Tune Bass",
    es: "Afinar Bajo",
    fr: "Accorder Basse",
    zh: "调贝斯",
    de: "Bass Stimmen",
    id: "Stem Bass",
    ko: "베이스 조율",
    bg: "Настройване на Бас",
    jp: "ベースの調整",
    pt: "Afinar Baixo",
    tr: "Bas Ayarlama",
    vi: "Điều chỉnh Bass",
    tw: "調貝斯",
    pl: "Strojenie Basu",
    it: "Accordare Basso",
    sv: "Stäm Bas",
    fi: "Viritä Basso",
    hi: "बास ट्यून करें",
  },
  uke: {
    en: "uke",
    es: "uke",
    fr: "uke",
    zh: "尤克",
    de: "uke",
    id: "uke",
    ko: "uke",
    bg: "uke",
    jp: "uke",
    pt: "uke",
    tr: "uke",
    vi: "uke",
    tw: "尤克",
    pl: "uke",
    it: "uke",
    sv: "uke",
    fi: "uke",
    hi: "उके",
  },
  ukulele: {
    en: "Ukulele",
    es: "Ukelele",
    fr: "Ukulélé",
    zh: "尤克里里",
    de: "Ukulele",
    id: "Ukulele",
    ko: "우쿨렐레",
    bg: "Укулеле",
    jp: "ウクレレ",
    pt: "Ukulele",
    tr: "Ukulele",
    vi: "Ukulele",
    tw: "尤克里里",
    pl: "Ukulele",
    it: "Ukulele",
    sv: "Ukulele",
    fi: "Ukulele",
    hi: "उकलुले",
  },
  ukuleleJSX: {
    en: <span>Ukulele</span>,
    es: <span>Ukelele</span>,
    fr: <span>Ukulélé</span>,
    zh: <span>尤克里里</span>,
    de: <span>Ukulele</span>,
    id: <span>Ukulele</span>,
    ko: <span>우쿨렐레</span>,
    bg: <span>Укулеле</span>,
    jp: <span>ウクレレ</span>,
    pt: <span>Ukulele</span>,
    tr: <span>Ukulele</span>,
    vi: <span>Ukulele</span>,
    tw: <span>尤克里里</span>,
    pl: <span>Ukulele</span>,
    it: <span>Ukulele</span>,
    sv: <span>Ukulele</span>,
    fi: <span>Ukulele</span>,
    hi: <span>उकलुले</span>,
  },
  tune_ukulele: {
    en: "Tune Ukulele",
    es: "Afinar Ukelele",
    fr: "Accorder Ukulélé",
    zh: "调尤克里里",
    de: "Ukulele Stimmen",
    id: "Stem Ukulele",
    ko: "우쿨렐레 조율",
    bg: "Настройване на Укулеле",
    jp: "ウクレレの調整",
    pt: "Afinar Ukulele",
    tr: "Tune Ukulele",
    vi: "Điều chỉnh Ukulele",
    tw: "調尤克里里",
    pl: "Strojenie Ukulele",
    it: "Accordare Ukulele",
    sv: "Stäm Ukulele",
    fi: "Viritä Ukulele",
    hi: "उकलुले ट्यून करें",
  },
  electric: {
    en: "Electric",
    es: "Eléctrica",
    fr: "Électrique",
    zh: "电",
    de: "Elektrische",
    id: "Elektrik",
    ko: "일렉트릭",
    bg: "Електрическа",
    jp: "エレクトリック",
    pt: "Elétrico",
    tr: "Elektrik",
    vi: "Điện",
    tw: "電",
    pl: "Elektryczna",
    it: "Elettrica",
    sv: "Elektrisk",
    fi: "Sähköinen",
    hi: "इलेक्ट्रिक",
  },
  electricJSX: {
    en: (
      <span>
        Electric<span className="xl-only">&nbsp;Guitar</span>
      </span>
    ),
    es: (
      <span>
        <span className="xl-only">Guitarra&nbsp;</span>Eléctrica
      </span>
    ),
    fr: (
      <span>
        <span className="xl-only">Guitare&nbsp;</span>Électrique
      </span>
    ),
    zh: <span>电吉他</span>,
    de: (
      <span>
        Elektrische<span className="xl-only">&nbsp;Gitarre</span>
      </span>
    ),
    id: (
      <span>
        Elektrik<span className="xl-only">&nbsp;Gitar</span>
      </span>
    ),
    ko: (
      <span>
        일렉트릭<span className="xl-only">&nbsp;기타</span>
      </span>
    ),
    bg: (
      <span>
        Електрическа<span className="xl-only">&nbsp;Китара</span>
      </span>
    ),
    jp: (
      <span>
        エレクトリック<span className="xl-only">&nbsp;ギター</span>
      </span>
    ),
    pt: (
      <span>
        Elétrico<span className="xl-only">&nbsp;Guitarra</span>
      </span>
    ),
    tr: (
      <span>
        Elektrik<span className="xl-only">&nbsp;Gitar</span>
      </span>
    ),
    vi: (
      <span>
        Điện<span className="xl-only">&nbsp;Guitar</span>
      </span>
    ),
    tw: <span>電吉他</span>,
    pl: (
      <span>
        Elektryczna<span className="xl-only">&nbsp;Gitara</span>
      </span>
    ),
    it: (
      <span>
        Elettrica<span className="xl-only">&nbsp;Chitarra</span>
      </span>
    ),
    sv: (
      <span>
        Elektrisk<span className="xl-only">&nbsp;Gitarr</span>
      </span>
    ),
    fi: (
      <span>
        Sähköinen<span className="xl-only">&nbsp;Kitara</span>
      </span>
    ),
    hi: (
      <span>
        इलेक्ट्रिक<span className="xl-only">&nbsp;गिटार</span>
      </span>
    ),
  },
  tune_electric: {
    en: "Tune Electric",
    es: "Afinar Eléctrica",
    fr: "Accorder Électrique",
    zh: "调电吉他",
    de: "Elektrische Stimmen",
    id: "Stem Elektrik",
    ko: "일렉트릭 조율",
    bg: "Настройване на Електрическа",
    jp: "エレクトリックの調整",
    pt: "Afinar Elétrico",
    tr: "Elektrik Ayarlama",
    vi: "Điều chỉnh Điện",
    tw: "調電吉他",
    pl: "Strojenie Elektrycznej",
    it: "Accordare Elettrica",
    sv: "Stäm Elektrisk",
    fi: "Viritä Sähköinen",
    hi: "इलेक्ट्रिक ट्यून करें",
  },

  /***** UI *****/

  onlineGuitarTuner: {
    en: "Online Guitar Tuner",
    es: "Afinador de Guitarra Online",
    fr: "Accordeur de Guitare en Ligne",
    zh: "在线吉他调音器",
    de: "Stimmgerät Gitarre Online",
    id: "Tuner Gitar Online",
    ko: "온라인 기타 튜너",
    bg: "Онлайн тунер за китара",
    jp: "オンラインギターチューナー",
    pt: "Afinador de Guitarra Online",
    tr: "Çevrimiçi Gitar Akort Cihazı",
    vi: "Tuner Guitar Online",
    tw: "在線吉他調音器",
    pl: "Stroik do Gitary Online",
    it: "Accordatore di Chitarra Online",
    sv: "Gitarrstämning Online",
    fi: "Verkkokitaran Viritin",
    hi: "ऑनलाइन गिटार ट्यूनर",
  },
  onlineAcousticTuner: {
    en: "Acoustic Guitar Online Tuner",
    es: "Afinador de Guitarra Acústica Online",
    fr: "Accordeur de Guitare Acoustique en Ligne",
    zh: "在线原声吉他调音器",
    de: "Stimmgerät Akustik-Gitarre Online",
    id: "Tuner Gitar Akustik Online",
    ko: "온라인 어쿠스틱 기타 튜너",
    bg: "Онлайн тунер за акустична китара",
    jp: "オンラインアコースティックギターチューナー",
    pt: "Afinador de Guitarra Acústica Online",
    tr: "Çevrimiçi Akustik Gitar Akort Cihazı",
    vi: "Tuner Guitar Acoustic Online",
    tw: "在線木吉他調音器",
    pl: "Stroik do Gitary Akustycznej Online",
    it: "Accordatore di Chitarra Acustica Online",
    sv: "Akustisk Gitarrstämning Online",
    fi: "Verkkokitaran Akustinen Viritin",
    hi: "ऑनलाइन ध्वनिक गिटार ट्यूनर",
  },
  onlineElectricTuner: {
    en: "Electric Guitar Online Tuner",
    es: "Afinador de Guitarra Eléctrica Online",
    fr: "Accordeur de Guitare Électrique en Ligne",
    zh: "在线电吉他调音器",
    de: "Stimmgerät Elektrische Gitarre Online",
    id: "Tuner Gitar Elektrik Online",
    ko: "온라인 일렉트릭 기타 튜너",
    bg: "Онлайн тунер за електрическа китара",
    jp: "オンラインエレクトリックギターチューナー",
    pt: "Afinador de Guitarra Elétrica Online",
    tr: "Çevrimiçi Elektrik Gitar Akort Cihazı",
    vi: "Tuner Guitar Điện Online",
    tw: "在線電吉他調音器",
    pl: "Stroik do Gitary Elektrycznej Online",
    it: "Accordatore di Chitarra Elettrica Online",
    sv: "Elektrisk Gitarrstämning Online",
    fi: "Verkkokitaran Sähköinen Viritin",
    hi: "ऑनलाइन इलेक्ट्रिक गिटार ट्यूनर",
  },
  onlineBassTuner: {
    en: "Bass Guitar Online Tuner",
    es: "Afinador de Bajo Online",
    fr: "Accordeur de Guitare Basse en Ligne",
    zh: "低音吉他调音器在线",
    de: "Bass Stimmgerät Online",
    id: "Tuner Bass Gitar Online",
    ko: "온라인 베이스 기타 튜너",
    bg: "Онлайн тунер за бас китара",
    jp: "オンラインベースギターチューナー",
    pt: "Afinador de Baixo Online",
    tr: "Çevrimiçi Bas Gitar Akort Cihazı",
    vi: "Tuner Bass Guitar Online",
    tw: "在線貝斯吉他調音器",
    pl: "Stroik do Gitary Basowej Online",
    it: "Accordatore di Basso Online",
    sv: "Basgitarrstämning Online",
    fi: "Verkkokitaran Bassoviritin",
    hi: "ऑनलाइन बास गिटार ट्यूनर",
  },
  onlineUkuleleTuner: {
    en: "Ukulele Online Tuner",
    es: "Afinador de Ukelele Online",
    fr: "Accordeur d'Ukulélé en Ligne",
    zh: "尤克里里调音器在线",
    de: "Ukulele Stimmgerät Online",
    id: "Tuner Ukulele Online",
    ko: "온라인 우쿨렐레 튜너",
    bg: "Онлайн тунер за укулеле",
    jp: "オンラインウクレレチューナー",
    pt: "Afinador de Ukulele Online",
    tr: "Çevrimiçi Ukulele Akort Cihazı",
    vi: "Tuner Ukulele Online",
    tw: "在線尤克里里調音器",
    pl: "Stroik do Ukulele Online",
    it: "Accordatore di Ukulele Online",
    sv: "Ukulelestämning Online",
    fi: "Verkkokitaran Ukulelen Viritin",
    hi: "ऑनलाइन उकलुले ट्यूनर",
  },
  tuneYourInstruments: {
    en: "Tune your acoustic, electric, bass guitar or ukulele",
    es: "Afina tu ukelele, guitarra acústica, eléctrica o baja",
    fr: "Accordez votre ukulélé, votre guitare acoustique, électrique ou basse",
    zh: "调好你的尤克里里、木吉他、电吉他或贝斯",
    de: "Stimmen Sie Ihre Ukulele, akustik, elektrische oder Bassgitarre",
    id: "Stem ukulele, gitar akustik, elektrik, atau bass Anda",
    ko: "우쿨렐레, 어쿠스틱, 일렉트릭, 베이스 기타를 조율하세요",
    bg: "Настройте вашето укулеле, акустична, електрическа или бас китара",
    jp: "ウクレレ、アコースティック、エレクトリック、ベースギターを調整します",
    pt: "Ajuste seu ukulele, guitarra acústica, elétrica ou baixo",
    tr: "Ukulele, akustik, elektrik, bas gitarınızı ayarlayın",
    vi: "Điều chỉnh ukulele, guitar acoustic, electric hoặc bass của bạn",
    tw: "調好你的尤克里里、木吉他、电吉他或貝斯",
    pl: "Strojenie ukulele, gitary akustycznej, elektrycznej lub basowej",
    it: "Accorda il tuo ukulele, chitarra acustica, elettrica o basso",
    sv: "Stäm din ukulele, akustiska, elektriska eller basgitarr",
    fi: "Viritä ukulele, akustinen, sähköinen tai bassokitara",
    hi: "अपने उकलुले, ध्वनिक, इलेक्ट्रिक या बास गिटार को ट्यून करें",
  },
  withMicrophoneOrByEar: {
    en: "with Microphone or by Ear",
    es: "con un Micrófono o de Oído",
    fr: "avec un Microphone ou à l'Oreille",
    zh: "用麦克风或耳朵",
    de: "mit dem Mikrofon oder nach Gehör",
    id: "dengan Mikrofon atau dengan Telinga",
    ko: "마이크 또는 귀로",
    bg: "с микрофон или на ухо",
    jp: "マイクまたは耳で",
    pt: "com Microfone ou de Ouvido",
    tr: "Mikrofonla veya kulakla",
    vi: "với Micro hoặc bằng Tai",
    tw: "用麥克風或耳朵",
    pl: "z Mikrofonem lub na słuch",
    it: "con Microfono o a orecchio",
    sv: "med Mikrofon eller på gehör",
    fi: "Mikrofonilla tai korvakuulolta",
    hi: "माइक्रोफ़ोन या कान से",
  },
  instrument: {
    en: "Instrument",
    es: "Instrumento",
    fr: "Instrument",
    zh: "乐器",
    de: "Instrument",
    id: "Instrumen",
    ko: "악기",
    bg: "Инструмент",
    jp: "楽器",
    pt: "Instrumento",
    tr: "Enstrüman",
    vi: "Nhạc cụ",
    tw: "樂器",
    pl: "Instrument",
    it: "Strumento",
    sv: "Instrument",
    fi: "Soitin",
    hi: "वाद्य यंत्र",
  },
  selectTuning: {
    en: "Select tuning",
    es: "Seleccione afinación",
    fr: "Accordage",
    zh: "选择调音",
    de: "Stimmung",
    id: "Pilih penyetelan",
    ko: "튜닝 선택",
    bg: "Изберете настройка",
    jp: "調整を選択",
    pt: "Selecionar afinação",
    tr: "Akort Seçimi",
    vi: "Chọn chỉnh âm",
    tw: "選擇調音",
    pl: "Wybierz strojenie",
    it: "Seleziona l'accordatura",
    sv: "Välj stämning",
    fi: "Valitse viritys",
    hi: "ट्यूनिंग चुनें",
  },
  microphone: {
    en: "Microphone",
    es: "Micrófono",
    fr: "Microphone",
    zh: "麦克风",
    de: "Mikrofon",
    id: "Mikrofon",
    ko: "마이크",
    bg: "Микрофон",
    jp: "マイク",
    pt: "Microfone",
    tr: "Mikrofon",
    vi: "Micro",
    tw: "麥克風",
    pl: "Mikrofon",
    it: "Microfono",
    sv: "Mikrofon",
    fi: "Mikrofoni",
    hi: "माइक्रोफोन",
  },
  loopNote: {
    en: "Loop Note",
    es: "Repetir la nota",
    fr: "Répéter la note",
    zh: "重复音符",
    de: "Schleifen Notiz",
    id: "Ulangi Nada",
    ko: "루프 노트",
    bg: "Повтаряне на нотата",
    jp: "ループノート",
    pt: "Repetir Nota",
    tr: "Döngü Notu",
    vi: "Lặp lại nốt",
    tw: "重複音符",
    pl: "Zapętlenie nuty",
    it: "Ripeti Nota",
    sv: "Repetera Not",
    fi: "Toista Nuotti",
    hi: "लूप नोट",
  },
  detectString: {
    en: "Auto Detect String",
    es: "Auto Detección",
    fr: "Auto-corde détection",
    zh: "检测弦",
    de: "Saite erkennen",
    id: "Deteksi String Otomatis",
    ko: "자동 검출 문자열",
    bg: "Автоматично разпознаване на струна",
    jp: "弦の自動検出",
    pt: "Auto Detecção de Corda",
    tr: "Otomatik Tel Algılama",
    vi: "Tự động phát hiện dây",
    tw: "自動檢測弦",
    pl: "Automatyczne Wykrywanie Struny",
    it: "Rilevamento Automatico della Corda",
    sv: "Automatisk Strängdetektering",
    fi: "Automaattinen Kielen Tunnistus",
    hi: "ऑटो डिटेक्ट स्ट्रिंग",
  },
  usingMicrophone: {
    en: "Using Microphone",
    es: "El uso de micrófono",
    fr: "En utilisant le microphone",
    zh: "用麦克风",
    de: "Verwendung des Mikrofons",
    id: "Menggunakan Mikrofon",
    ko: "마이크 사용 중",
    bg: "Използване на микрофон",
    jp: "マイクを使用中",
    pt: "Usando Microfone",
    tr: "Mikrofon Kullanarak",
    vi: "Sử dụng Micro",
    tw: "用麥克風",
    pl: "Korzystanie z Mikrofonu",
    it: "Utilizzando il Microfono",
    sv: "Använder Mikrofon",
    fi: "Mikrofonin Käyttö",
    hi: "माइक्रोफोन का उपयोग करना",
  },
  byEar: {
    en: "By Ear",
    es: "De oído",
    fr: "À l'oreille",
    zh: "用耳朵调音",
    de: "Nach Gehör",
    id: "Dengan Telinga",
    ko: "귀로",
    bg: "По слух",
    jp: "耳で",
    pt: "De ouvido",
    tr: "Kulaktan",
    vi: "Bằng Tai",
    tw: "用耳朵調音",
    pl: "Na słuch",
    it: "A orecchio",
    sv: "Efter Gehör",
    fi: "Korvakuulolta",
    hi: "कान से",
  },
  auto: {
    en: "Auto",
    es: "Auto",
    fr: "Auto",
    zh: "带麦克风",
    de: "Auto",
    id: "Otomatis",
    ko: "자동",
    bg: "Автоматично",
    jp: "オート",
    pt: "Auto",
    tr: "Oto",
    vi: "Tự động",
    tw: "自動",
    pl: "Auto",
    it: "Auto",
    sv: "Auto",
    fi: "Auto",
    hi: "ऑटो",
  },
  tuningByEar: {
    en: "Tuning by Ear",
    es: "La afinación de oído",
    fr: "En accordant à l'oreille",
    zh: "用耳朵调音",
    de: "Stimmen nach Gehör",
    id: "Penyetelan dengan Telinga",
    ko: "귀로 조율",
    bg: "Настройване на ухо",
    jp: "耳で調整",
    pt: "Afinação de ouvido",
    tr: "Kulaktan Akort",
    vi: "Chỉnh âm bằng tai",
    tw: "用耳朵調音",
    pl: "Strojenie na słuch",
    it: "Accordatura a orecchio",
    sv: "Stämning efter Gehör",
    fi: "Viritys Korvakuulolta",
    hi: "कान से ट्यूनिंग",
  },
  comingSoon: {
    en: "Coming soon",
    es: "Próximamente",
    fr: "Bientôt disponible",
    zh: "快来了",
    de: "Demnächst",
    id: "Segera hadir",
    ko: "곧 출시 예정",
    bg: "Очаквайте скоро",
    jp: "近日公開",
    pt: "Em breve",
    tr: "Yakında",
    vi: "Sắp ra mắt",
    tw: "即將推出",
    pl: "Już wkrótce",
    it: "Prossimamente",
    sv: "Kommer snart",
    fi: "Tulossa pian",
    hi: "जल्द आ रहा है",
  },
  tapTheNoteOnce: {
    en: (
      <>
        Tap the note <strong>once</strong>
      </>
    ),
    es: (
      <>
        Pulsa la nota <strong>una vez</strong>
      </>
    ),
    fr: (
      <>
        Appuyez <strong>une fois</strong> sur la note
      </>
    ),
    zh: (
      <>
        点击音调<strong>一次</strong>
      </>
    ),
    de: (
      <>
        Tippen Sie <strong>einmal</strong> auf die Note
      </>
    ),
    id: (
      <>
        Ketuk nada <strong>sekali</strong>
      </>
    ),
    ko: (
      <>
        음표를 <strong>한 번</strong> 누르세요
      </>
    ),
    bg: (
      <>
        Докоснете нотата <strong>веднъж</strong>
      </>
    ),
    jp: (
      <>
        音符を<strong>一度</strong>タップしてください
      </>
    ),
    pt: (
      <>
        Toque na nota <strong>uma vez</strong>
      </>
    ),
    tr: (
      <>
        Notaya <strong>bir kez</strong> dokunun
      </>
    ),
    vi: (
      <>
        Nhấn vào nốt <strong>một lần</strong>
      </>
    ),
    tw: (
      <>
        點擊音符 <strong>一次</strong>
      </>
    ),
    pl: (
      <>
        Stuknij w nutę <strong>raz</strong>
      </>
    ),
    it: (
      <>
        Tocca la nota <strong>una volta</strong>
      </>
    ),
    sv: (
      <>
        Tryck på noten <strong>en gång</strong>
      </>
    ),
    fi: (
      <>
        Napauta nuottia <strong>kerran</strong>
      </>
    ),
    hi: (
      <>
        नोट पर <strong>एक बार</strong> टैप करें
      </>
    ),
  },
  listening: {
    en: "Listening...",
    es: "Escuchando...",
    fr: "Écoute...",
    zh: "正在听。。。",
    de: "Spiel eine Saite...",
    id: "Mendengarkan...",
    ko: "듣는 중...",
    bg: "Слушам...",
    jp: "聞いています...",
    pt: "Ouvindo...",
    tr: "Dinliyor...",
    vi: "Đang nghe...",
    tw: "聽...",
    pl: "Słucham...",
    it: "Ascoltando...",
    sv: "Lyssnar...",
    fi: "Kuunnellaan...",
    hi: "सुन रहा हूँ...",
  },
  tooLow: {
    en: "Too low",
    es: "Muy baja",
    fr: "Trop bas",
    zh: "太低",
    de: "Zu niedrig",
    id: "Terlalu rendah",
    ko: "너무 낮음",
    bg: "Твърде ниско",
    jp: "低すぎる",
    pt: "Muito baixo",
    tr: "Çok düşük",
    vi: "Quá thấp",
    tw: "太低",
    pl: "Zbyt nisko",
    it: "Troppo basso",
    sv: "För lågt",
    fi: "Liian matala",
    hi: "बहुत कम",
  },
  tooHigh: {
    en: "Too high",
    es: "Muy alta",
    fr: "Trop élevé",
    zh: "太高",
    de: "Zu hoch",
    id: "Terlalu tinggi",
    ko: "너무 높음",
    bg: "Твърде високо",
    jp: "高すぎる",
    pt: "Muito alto",
    tr: "Çok yüksek",
    vi: "Quá cao",
    tw: "太高",
    pl: "Zbyt wysoko",
    it: "Troppo alto",
    sv: "För högt",
    fi: "Liian korkea",
    hi: "बहुत ऊँचा",
  },
  switchMicrophoneTooltip: {
    en: "To tune with microphone, tap the switch.",
    es: "Para afinar con micrófono, toca el interruptor.",
    fr: "Pour accorder avec le microphone, appuyez sur le interrupteur.",
    zh: "更换模式为用麦克风调音",
    de: "Tippen Sie auf den Schalter, um mit dem Mikrofon zu stimmen.",
    id: "Untuk menyetel dengan mikrofon, ketuk sakelar.",
    ko: "마이크로 조율하려면 스위치를 누르세요.",
    bg: "За да настроите с микрофон, докоснете превключвателя.",
    jp: "マイクで調整するには、スイッチをタップします。",
    pt: "Para afinar com microfone, toque no interruptor.",
    tr: "Mikrofonla akort etmek için anahtara dokunun.",
    vi: "Để chỉnh âm với micro, chạm vào công tắc.",
    tw: "切換模式為用麥克風調音。",
    pl: "Aby nastroić za pomocą mikrofonu, stuknij w przełącznik.",
    it: "Per accordare con il microfono, tocca l'interruttore.",
    sv: "För att stämma med mikrofon, tryck på omkopplaren.",
    fi: "Viritä mikrofonilla napauttamalla kytkintä.",
    hi: "माइक्रोफ़ोन के साथ ट्यून करने के लिए, स्विच को टैप करें।",
  },

  /***** Text *****/

  contactUsAt: {
    en: "Contact us at:",
    es: "Contacta con nosotros en:",
    fr: "Contactez-nous à:",
    zh: "联系我们:",
    de: "Kontaktieren Sie uns bei:",
    id: "Hubungi kami di:",
    ko: "연락처:",
    bg: "Свържете се с нас на:",
    jp: "お問い合わせ先:",
    pt: "Contate-Nos Em:",
    tr: "Bize Ulaşın:",
    vi: "Liên hệ với chúng tôi tại:",
    tw: "聯繫我們：",
    pl: "Skontaktuj się z nami:",
    it: "Contattaci a:",
    sv: "Kontakta oss på:",
    fi: "Ota meihin yhteyttä:",
    hi: "संपर्क करें:",
  },
  "acoustic-guitar": {
    en: "acoustic guitar",
    es: "guitarra acústica",
    fr: "guitare acoustique",
    zh: "木吉他",
    de: "akustik Gitarre",
    id: "gitar akustik",
    ko: "어쿠스틱 기타",
    bg: "акустична китара",
    jp: "アコースティックギター",
    pt: "violão acústico",
    tr: "akustik gitar",
    vi: "đàn guitar acoustic",
    tw: "木吉他",
    pl: "gitara akustyczna",
    it: "chitarra acustica",
    sv: "akustisk gitarr",
    fi: "akustinen kitara",
    hi: "ध्वनिक गिटार",
  },
  "electric-guitar": {
    en: "electric guitar",
    es: "guitarra eléctrica",
    fr: "guitare électrique",
    zh: "电吉他",
    de: "elektrische Gitarre",
    id: "gitar listrik",
    ko: "일렉트릭 기타",
    bg: "електрическа китара",
    jp: "エレキギター",
    pt: "guitarra elétrica",
    tr: "elektrik gitar",
    vi: "đàn guitar điện",
    tw: "電吉他",
    pl: "gitara elektryczna",
    it: "chitarra elettrica",
    sv: "elgitarr",
    fi: "sähkökitara",
    hi: "इलेक्ट्रिक गिटार",
  },
  "bass-guitar": {
    en: "bass guitar",
    es: "guitarra baja",
    fr: "guitare basse",
    zh: "贝斯吉他",
    de: "bass Gitarre",
    id: "gitar bass",
    ko: "베이스 기타",
    bg: "бас китара",
    jp: "ベースギター",
    pt: "baixo",
    tr: "bas gitar",
    vi: "đàn guitar bass",
    tw: "貝斯吉他",
    pl: "gitara basowa",
    it: "basso",
    sv: "basgitarr",
    fi: "bassokitara",
    hi: "बास गिटार",
  },
  guitar: {
    en: "guitar",
    es: "guitarra",
    fr: "guitare",
    zh: "吉他",
    de: "Gitarre",
    id: "gitar",
    ko: "기타",
    bg: "китара",
    jp: "ギター",
    pt: "guitarra",
    tr: "gitar",
    vi: "đàn guitar",
    tw: "吉他",
    pl: "gitara",
    it: "chitarra",
    sv: "gitarr",
    fi: "kitara",
    hi: "गिटार",
  },
  howToUseHeader: {
    en: (
      <>
        HOW <strong>TO USE</strong>
      </>
    ),
    es: (
      <>
        ¿CÓMO <strong>USAR?</strong>
      </>
    ),
    fr: (
      <>
        COMMENT <strong>UTILISER</strong>
      </>
    ),
    zh: (
      <>
        怎么<strong>使用</strong>
      </>
    ),
    de: (
      <>
        ZUR <strong>ANWENDUNG</strong>
      </>
    ),
    id: (
      <>
        CARA <strong>MENGGUNAKAN</strong>
      </>
    ),
    ko: (
      <>
        사용 <strong>방법</strong>
      </>
    ),
    bg: (
      <>
        КАК <strong>ДА ИЗПОЛЗВАМ</strong>
      </>
    ),
    jp: (
      <>
        使い方 <strong>使用する</strong>
      </>
    ),
    pt: (
      <>
        COMO <strong>USAR</strong>
      </>
    ),
    tr: (
      <>
        NASIL <strong>KULLANILIR</strong>
      </>
    ),
    vi: (
      <>
        CÁCH <strong>SỬ DỤNG</strong>
      </>
    ),
    tw: (
      <>
        如何<strong>使用</strong>
      </>
    ),
    pl: (
      <>
        JAK <strong>UŻYWAĆ</strong>
      </>
    ),
    it: (
      <>
        COME <strong>USARE</strong>
      </>
    ),
    sv: (
      <>
        HUR <strong>MAN ANVÄNDER</strong>
      </>
    ),
    fi: (
      <>
        KUINKA <strong>KÄYTTÄÄ</strong>
      </>
    ),
    hi: (
      <>
        कैसे <strong>उपयोग करें</strong>
      </>
    ),
  },

  howToUseText: {
    en: ({ pageData }) => additionals[pageData]?.howToUseText?.en,
    es: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.es ||
      additionals[pageData]?.howToUseText?.en,
    fr: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.fr ||
      additionals[pageData]?.howToUseText?.en,
    de: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.de ||
      additionals[pageData]?.howToUseText?.en,
    zh: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.zh ||
      additionals[pageData]?.howToUseText?.en,
    id: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.id ||
      additionals[pageData]?.howToUseText?.en,
    ko: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.ko ||
      additionals[pageData]?.howToUseText?.en,
    bg: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.bg ||
      additionals[pageData]?.howToUseText?.en,
    jp: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.jp ||
      additionals[pageData]?.howToUseText?.en,
    pt: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.pt ||
      additionals[pageData]?.howToUseText?.en,
    tr: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.tr ||
      additionals[pageData]?.howToUseText?.en,
    vi: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.vi ||
      additionals[pageData]?.howToUseText?.en,
    tw: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.tw ||
      additionals[pageData]?.howToUseText?.en,
    pl: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.pl ||
      additionals[pageData]?.howToUseText?.en,
    it: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.it ||
      additionals[pageData]?.howToUseText?.en,
    sv: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.sv ||
      additionals[pageData]?.howToUseText?.en,
    fi: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.fi ||
      additionals[pageData]?.howToUseText?.en,
    hi: ({ pageData }) =>
      additionals[pageData]?.howToUseText?.hi ||
      additionals[pageData]?.howToUseText?.en,
  },

  previewTitle: {
    en: (
      <>
        Tuned Up? <br /> <strong>Play Some Chords</strong>
      </>
    ),
  }
};

export default strings;
